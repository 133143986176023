.advancedInformation {
    background-color: #69e9cb;
    text-align: center;
    margin:auto;
    width: 100%;
    min-width: 50px;
    max-width: 400px; 
    border-radius: 12px;
}

.advancedInformation #ele {
    margin-bottom: -15px;
}

.advancedInformation #addr {
    overflow-wrap: break-word;
    margin-bottom: -15px;

}

.advancedInformation button{
    background-color: #CBE4DE;
    min-width: 40px;
    max-width: 200px; 
    border-radius:12px;
    }
    .advancedInformation button:hover,
    .advancedInformation button:active{
      background-color: #00FFCA;
      border-color: #000000;;
    }