.logger {
  background-color: #69e9cb;
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 450px; 
  border-radius: 12px;
}

.logger p {
  padding:.1rem;
} 