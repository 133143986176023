.contractManagement {
    text-align: center;
}

.contractManagement button{
  background-color: #CBE4DE;
  width: 100%;
  min-width: 50px;
  max-width: 300px; 
  font-size:24px;
  border-radius:12px;
  margin:2.5px;
  }
  .contractManagement button:hover,
  .contractManagement button:active{
    background-color: #00FFCA;
    border-color: #000000;;
  }

.contractManagement table {
    margin-left: auto;
    margin-right: auto;
}

.contractManagement table,
.contractManagement th,
.contractManagement td {
    border: 2.5px solid black;
    border-color:#010202;
    border-radius: 12px;
    background-color: #0E8388;
    border-collapse: collapse;
    font-size: larger;
}

.contractManagement a:link {
  color: rgb(0, 62, 128);
}

/* visited link */
.contractManagement a:visited {
  color: rgb(0, 62, 128);
}

/* mouse over link */
.contractManagement a:hover {
  color: #00FFCA;
}

/* selected link */
.contractManagement a:active {
  color: #00FFCA;
}