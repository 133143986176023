.connectWallet {
  background-color: #69e9cb;
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 250px; 
  border-radius: 12px;
  }

.connectWallet button{
  background-color: #CBE4DE;
  width: 100%;
  min-width: 50px;
  max-width: 125px; 
  font-size:24px;
  border-radius:12px;
  margin:2.5px;
  }
  .connectWallet button:hover,
  .connectWallet button:active{
    background-color: #00FFCA;
    border-color: #000000;;
  }